import { DashboardResult } from '@app/models';
import { Color } from '@bcase/core';
import { ChartData, ChartOptions } from 'chart.js';

import { ChartType } from '../models/chart-type';
import { i18n } from '../store/modules/cms-module';

type Dataset = DashboardResult['dataset'];
type DatasetObj = { [key: number]: number[] };

export class ChartUtil {
  public static readonly minBarLength = 2;

  public static chartAxes(result: DashboardResult) {
    return {
      xAxes: { title: ChartUtil._scaleLabel(result.Xaxis) },
      yAxes: { title: ChartUtil._scaleLabel(result.Yaxis) },
    };
  }

  public static color(length: number) {
    const root = document.querySelector('bce-root');
    if (!root) return [];

    // Parse base color
    const style = getComputedStyle(root);
    const pBase = style.getPropertyValue('--bce-c500-primary').trim();
    const sBase = style.getPropertyValue('--bce-c500-secondary').trim();
    const dBase = style.getPropertyValue('--bce-c500-dark').trim();
    const p = new Color(pBase);
    const s = new Color(sBase);
    const d = new Color(dBase);

    // Create light variant
    const p5 = this.colorLighten(p, 0.85).hex;
    const s5 = this.colorLighten(s, 0.85).hex;
    const d5 = this.colorLighten(d, 0.85).hex;
    if (length === 1) return [p5];
    if (length === 2) return [p5, s5];
    if (length === 3) return [p5, s5, d5];

    // Create extra variants
    const p3 = this.colorLighten(p, 0.3).hex;
    const s3 = this.colorLighten(s, 0.3).hex;
    const d3 = this.colorLighten(d, 0.3).hex;
    const p4 = this.colorLighten(p, 0.6).hex;
    const s4 = this.colorLighten(s, 0.6).hex;
    const d4 = this.colorLighten(d, 0.6).hex;

    // Repeat/trim until for required length
    const base = [p3, s3, d3, p5, s5, d5, p4, s4, d4];
    return ChartUtil._colorLength(base, length);
  }

  public static colorLighten(color: Color, weight = 0.5) {
    const mix = new Color('#fff');
    return Color.mix(color, mix, weight);
  }

  public static colorOn(length: number) {
    const root = document.querySelector('bce-root');
    if (!root) return [];

    const style = getComputedStyle(root);
    const p = style.getPropertyValue('--bce-con3-primary');
    const s = style.getPropertyValue('--bce-con3-secondary');
    const d = style.getPropertyValue('--bce-con3-dark');
    if (length === 1) return [p];
    if (length === 2) return [p, s];
    if (length === 3) return [p, s, d];

    const base = [p, s, d, p, s, d, p, s, d];
    return ChartUtil._colorLength(base, length);
  }

  public static findIndexes(
    data: number[],
    count: number,
    type: 'max' | 'min'
  ) {
    const max = type === 'max';
    let output: number[] = [];
    for (let i = 0; i < data.length; i++) {
      output.push(i);

      if (output.length > count) {
        output = max
          ? output.sort((a, b) => data[b] - data[a])
          : output.sort((a, b) => data[a] - data[b]);
        output.pop();
      }
    }

    return output;
  }

  public static normalize(dataset: number[]): number[];
  public static normalize(dataset: DatasetObj): DatasetObj;
  public static normalize(dataset: Dataset): Dataset;
  public static normalize(dataset: Dataset): Dataset {
    return Array.isArray(dataset)
      ? ChartUtil._normalizeArray(dataset)
      : ChartUtil._normalizeObject(dataset);
  }

  public static toChartData(type: ChartType, result: DashboardResult) {
    return type === 'doughnut'
      ? ChartUtil._toChartDataDoughnut(result)
      : ChartUtil._toChartDataBar(result);
  }

  public static toChartOptions(type: string, result: DashboardResult) {
    return type === 'doughnut'
      ? ChartUtil._toChartOptionsDoughnut(result)
      : ChartUtil._toChartOptionsBar(result);
  }

  private static _colorLength(base: string[], length: number) {
    if (base.length === length) return base;

    // Repeat base array until we have the required amount of colors
    if (base.length < length) {
      return Array(Math.ceil(length / base.length))
        .fill(base)
        .flat()
        .slice(0, length);
    }

    // Use middle values of given length
    const middle = Math.floor(base.length / 2);
    const start = Math.floor(middle - length / 2);
    const end = Math.floor(middle + length / 2);
    return base.slice(start, end);
  }

  private static _normalizeArray(dataset: number[]): number[] {
    const total = dataset.reduce((acc, v) => acc + v, 0);
    return dataset.map(v => v / total);
  }

  private static _normalizeObject(dataset: DatasetObj): DatasetObj {
    const total = Object.keys(dataset).reduce((acc, val) => {
      const t = dataset[val as any].reduce((a, v) => a + v, 0);
      return Math.max(acc, t);
    }, 0);

    return Object.keys(dataset).reduce((acc, val) => {
      acc[val as any] = dataset[val as any].map(v => v / total);
      return acc;
    }, {} as DatasetObj);
  }

  private static _parseLabels(labels: any[], smiley = false) {
    const smile = smiley && labels.length === 5;

    return labels.map(label => {
      const text = (typeof label !== 'object' ? label : label.text) || '';

      if ((smile && label === '1') || text === 'fas:sad-tear') return '😞';
      if ((smile && label === '2') || text === 'fas:frown-open') return '🙁';
      if ((smile && label === '3') || text === 'fas:meh') return '😐';
      if ((smile && label === '4') || text === 'fas:grin') return '🙂';
      if ((smile && label === '5') || text === 'fas:laugh') return '😃';
      return text;
    });
  }

  private static _scaleLabel(label?: string) {
    return { text: label || '', display: !!label };
  }

  private static _toChartDataBar(result: DashboardResult): ChartData<'bar'> {
    const normalized = ChartUtil.normalize(result.dataset);
    const labels = ChartUtil._parseLabels(result.labels);

    if (Array.isArray(normalized)) {
      const length = normalized.length;

      return {
        datasets: [
          {
            backgroundColor: ChartUtil.color(length),
            data: normalized,
            minBarLength: ChartUtil.minBarLength,
          },
        ],
        labels,
      };
    }

    const arr = Object.values(normalized).reduce((acc, data) => {
      for (let i = 0; i < data.length; i++)
        acc[i] = [...(acc[i] || []), data[i]];
      return acc;
    }, [] as number[][]);
    const colors = ChartUtil.color(arr.length);
    const dataLabels = ChartUtil._parseLabels(
      (result as any).labelsDataset,
      true
    );

    return {
      datasets: arr.map((data, i) => ({
        backgroundColor: colors[i],
        data,
        label: dataLabels[i],
        minBarLength: ChartUtil.minBarLength,
      })),
      labels,
    };
  }

  private static _toChartDataDoughnut(
    result: DashboardResult
  ): ChartData<'doughnut'> {
    const normalized = ChartUtil.normalize(result.dataset);

    if (Array.isArray(normalized)) {
      const MAX = 4;
      const fit = normalized.length === MAX ? MAX : MAX - 1;
      const remainder = normalized.length > MAX;

      const i = ChartUtil.findIndexes(normalized, fit, 'max');
      const indexes = i.filter(i => !!normalized[i]);

      const data = [
        ...indexes.map(i => normalized[i]),
        remainder
          ? normalized
              .filter((_, i) => !indexes.includes(i))
              .reduce((acc, val) => acc + val, 0)
          : -1,
      ].filter(v => v >= 0);

      return {
        datasets: [
          {
            data,
            backgroundColor: [...ChartUtil.color(3), 'rgba(0, 0, 0, 0.2)'],
          },
        ],
        labels: ChartUtil._parseLabels(
          [
            ...i.map(i => result.labels[i]).slice(0, fit),
            remainder && i18n.t('remainder'),
          ].filter(Boolean)
        ),
      };
    }

    const arr = Object.values(normalized);
    const dataLabels = ChartUtil._parseLabels((result as any).labelsDataset);

    return {
      datasets: arr.map((data, i) => ({
        backgroundColor: ChartUtil.color(data.length),
        data,
        label: dataLabels[i],
        minBarLength: ChartUtil.minBarLength,
      })),
      labels: ChartUtil._parseLabels(result.labels),
    };
  }

  private static _toChartOptionsBar(r: DashboardResult): ChartOptions<'bar'> {
    return {
      scales: ChartUtil.chartAxes(r),
      plugins: { legend: { display: r.type === 'matrix' } },
    };
  }

  private static _toChartOptionsDoughnut(
    r: DashboardResult
  ): ChartOptions<'doughnut'> {
    return {};
  }
}
