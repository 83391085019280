






import {
  ArcElement,
  Chart,
  DoughnutController,
  Legend,
  Title,
  Tooltip,
  ChartData,
  ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import { ChartUtil } from '../../utils/chart';

Chart.register(
  ArcElement,
  ChartDataLabels,
  DoughnutController,
  Legend,
  Title,
  Tooltip
);

@Component
export default class ChartDoughnut extends Vue {
  @Prop({ type: Object, default: () => ({ datasets: [], labels: [] }) })
  public chartData!: ChartData<'doughnut'>;

  @Prop({ type: Object, default: () => ({}) })
  public chartOptions!: ChartOptions<'doughnut'>;

  @Ref('canvas')
  private canvas!: HTMLCanvasElement;

  private chart?: Chart<'doughnut', number[]>;

  public get defaultOptions(): ChartOptions<'doughnut'> {
    return {
      cutout: '70%',
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          color: context => {
            const length = context.dataset!.data!.length;
            const colors = ChartUtil.colorOn(length);
            return colors[context.dataIndex];
          },
          display: context => {
            const value = context.dataset!.data![context.dataIndex]!;
            return +value > 0.05;
          },
          formatter: value => {
            return Math.round(value * 100) + '%';
          },
        },
        legend: { position: 'bottom' },
        tooltip: {
          callbacks: {
            label: item => {
              const value = item.parsed;
              return (+value * 100).toFixed(2) + '%';
            },
          },
        },
      },
      responsive: true,
    };
  }

  mounted() {
    this.chart = new Chart(this.canvas, {
      type: 'doughnut',
      data: this.chartData,
      options: _.merge(this.defaultOptions, this.chartOptions),
    });
  }

  @Watch('chartData')
  public watchChartData() {
    if (!this.chart) return;
    this.chart.data = this.chartData;
    this.chart.update();
  }

  @Watch('chartOptions')
  public watchChartOptions() {
    if (!this.chart) return;
    this.chart.options = _.merge(this.defaultOptions, this.chartOptions);
    this.chart.update();
  }
}
