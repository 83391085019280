






import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  ChartData,
  ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import { ChartUtil } from '../../utils/chart';

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  Legend,
  Title,
  Tooltip
);

@Component
export default class ChartBar extends Vue {
  @Prop({ type: Object, default: () => ({ datasets: [], labels: [] }) })
  public chartData!: ChartData<'bar'>;

  @Prop({ type: Object, default: () => ({}) })
  public chartOptions!: ChartOptions<'bar'>;

  @Ref('canvas')
  private canvas!: HTMLCanvasElement;

  private chart?: Chart<'bar', number[]>;

  public get defaultOptions(): ChartOptions<'bar'> {
    const wrap = this.wrap.bind(this);

    return {
      scales: {
        xAxes: {
          ticks: {
            callback(this: CategoryScale, _, index) {
              const value = this.getLabelForValue(index);
              return wrap(value.toString(), 10) as any;
            },
          },
        },
        yAxes: {
          beginAtZero: true,
          max: 1,
          ticks: { callback: value => +value * 100 + '%' },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          color: context => {
            const length = context.dataset!.data!.length;
            const colors = ChartUtil.colorOn(length);
            return colors[context.dataIndex];
          },
          display: context => {
            return !!context.dataset.data[context.dataIndex];

            // const meta = context.chart.getDatasetMeta(context.datasetIndex);
            // const { height } = meta.data[context.dataIndex] as BarElement;
            // return height > 20 || height < -20;
          },
          formatter: value => {
            return Math.round(value * 100) + '%';
          },
          rotation: context => {
            const meta = context.chart.getDatasetMeta(context.datasetIndex);
            const { width } = meta.data[context.dataIndex] as any;
            return width > 30 ? 0 : 270;
          },
        },
        legend: { display: false },
        tooltip: {
          callbacks: {
            label(item) {
              return (+item.formattedValue * 100).toFixed(2) + '%';
            },
          },
        },
      },
      responsive: true,
    };
  }

  mounted() {
    this.chart = new Chart(this.canvas, {
      type: 'bar',
      data: this.chartData,
      options: _.merge(this.defaultOptions, this.chartOptions),
    });
  }

  @Watch('chartData')
  public watchChartData() {
    if (!this.chart) return;
    this.chart.data = this.chartData;
    this.chart.update();
  }

  @Watch('chartOptions')
  public watchChartOptions() {
    if (!this.chart) return;
    this.chart.options = _.merge(this.defaultOptions, this.chartOptions);
    this.chart.update();
  }

  private wrap(str: any, limit: any) {
    const words = str.split(' ');
    let aux = [];
    let concat = [];

    for (let i = 0; i < words.length; i++) {
      concat.push(words[i]);
      let join = concat.join(' ');
      if (join.length > limit) {
        aux.push(join);
        concat = [];
      }
    }

    if (concat.length) aux.push(concat.join(' ').trim());
    return aux;
  }
}
